<template>
  <c-box w="100%" marginBottom="20px">
    <c-link
      as="router-link"
      :to="{ name: 'nutri.detail-client', params: { clientId: client.id } }"
      p="0"
      m="0"
      h="auto"
      w="auto"
    >
      <c-flex
        w="100%"
        backgroundColor="white"
        :borderRadius="['6px', '12px']"
        border="1px solid #f2f2f2"
        box-shadow="0px 5px 30px 0px #0000000D"
        :px="['20px', '26px']"
        :py="['20px', '26px']"
      >
        <c-box
          :min-w="['50px', '96px']"
          :w="['50px', '96px']"
          :h="['50px', '96px']"
          :marginRight="['15px', '30px']"
          overflow="hidden"
          borderRadius="100%"
          backgroundColor="lightGray.900"
        >
          <c-image
            object-fit="cover"
            :src="getPhotoUser(client.photoUrl)"
            :alt="client.firstName"
          />
        </c-box>
        <c-box w="100%">
          <c-heading
            as="h3"
            pos="relative"
            marginBottom="10px"
            :fontSize="['14px', '18px']"
            :lineHeight="['21px', '27px']"
            fontWeight="700"
            color="black.900"
          >
            {{ client.firstName }} {{ client.lastName }}
            <c-box
              as="span"
              borderWidth="1px"
              borderStyle="1px solid"
              borderRadius="30px"
              :borderColor="client.programs.status | programColor"
              :color="client.programs.status | programColor"
              :bg="client.programs.status | programBgColor"
              px="10px"
              py="2px"
              fontSize="12px"
              lineHeight="18px"
              fontWeight="500"
              display="inline-block"
              verticalAlign="middle"
              marginLeft="8px"
              pos="absolute"
              top="0"
              right="0"
            >
              {{ client.programs.status | programStatus }}
            </c-box>
          </c-heading>
          <c-text
            v-if="client.programs.status === 'active'"
            fontFamily="Roboto"
            fontSize="16px"
            color="brand.900"
            marginBottom="20px"
          >
            {{ client.programs.name }}
          </c-text>
          <c-box
            w="100%"
            display="flex"
            :flexDirection="['column', 'row']"
            :alignItems="['start', 'center']"
          >
            <c-text
              v-if="client.phone"
              pos="relative"
              paddingLeft="20px"
              fontFamily="Roboto"
              :fontSize="['12px', '14px']"
              color="gray.900"
              marginRight="12px"
            >
              <c-image
                :src="require('@/assets/icon-phone.svg')"
                alt="icon"
                display="inline-block"
                marginRight="8px"
                pos="absolute"
                top="4px"
                left="0"
              />
              {{ client.phone }}
            </c-text>
            <c-text
              v-if="client.email"
              pos="relative"
              paddingLeft="20px"
              fontFamily="Roboto"
              :fontSize="['12px', '14px']"
              color="gray.900"
            >
              <c-image
                :src="require('@/assets/icon-mail.svg')"
                alt="icon"
                display="inline-block"
                marginRight="8px"
                pos="absolute"
                top="4px"
                left="0"
              />
              {{ client.email }}
            </c-text>
          </c-box>
        </c-box>
      </c-flex>
    </c-link>
  </c-box>
</template>

<script>
import generalMixin from "@/utils/general-mixins"

export default {
  name: "CardClient",
  props: ["client"],
  mixins: [generalMixin],
  filters: {
    programColor(status) {
      switch (status) {
        case "active":
          return "brand.900"
        case "pending":
          return "#F4CC46"
        case "done":
        case "non_active":
        default:
          return "#888"
      }
    },
    programBgColor(status) {
      switch (status) {
        case "active":
          return "#008C8133"
        case "pending":
          return "#F4CC4633"
        case "done":
        case "non_active":
        default:
          return "#88888833"
      }
    },
    programStatus(status) {
      if (status === "active") {
        return "Aktif"
      }
      if (status === "pending") {
        return "Pending"
      }
      if (status === "done" || status === "non_active") {
        return "Tidak Aktif"
      }
      return ""
    },
  },
}
</script>
