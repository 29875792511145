import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6b9f28c0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CHeading: require('@chakra-ui/vue').CHeading, CImage: require('@chakra-ui/vue').CImage, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItemOption: require('@chakra-ui/vue').CMenuItemOption, CMenuOptionGroup: require('@chakra-ui/vue').CMenuOptionGroup, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox, CText: require('@chakra-ui/vue').CText, CIcon: require('@chakra-ui/vue').CIcon, CListItem: require('@chakra-ui/vue').CListItem, CList: require('@chakra-ui/vue').CList, CGrid: require('@chakra-ui/vue').CGrid})
