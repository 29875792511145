<template>
  <c-box id="kuisioner">
    <Header />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        v-chakra="{
          '> nav': {
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
        }"
      >
        <c-breadcrumb
          marginTop="20px"
          marginBottom="20px"
          v-chakra="{
            ol: {
              li: {
                a: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
                span: {
                  fontSize: '14px',
                  fontFamily: 'Roboto',
                },
              },
            },
          }"
        >
          <c-breadcrumb-item>
            <c-breadcrumb-link
              href="#"
              color="brand.900"
              fontSize="14px"
              fontFammily="Roboto"
            >
              Beranda
            </c-breadcrumb-link>
          </c-breadcrumb-item>

          <c-breadcrumb-item isCurrentPage>
            <c-breadcrumb-link
              href="#"
              fontSize="14px !important"
              fontFammily="Roboto !important"
            >
              Manajemen Klien
            </c-breadcrumb-link>
          </c-breadcrumb-item>
        </c-breadcrumb>
        <c-box
          w="100%"
          :maxWidth="['inherit', '1200px']"
          mx="auto"
          :marginTop="['20px', '50px']"
          :display="['flex', 'block']"
          :borderBottom="['1px solid #F2F2F2', 'none']"
          :paddingBottom="['15px', '0']"
          :px="['20px', '0']"
          :marginBottom="['30px', '0']"
        >
          <c-flex justifyContent="space-between">
            <c-heading
              :display="['none', 'inline-block']"
              fontSize="24px"
              lineHeight="32px"
              color="black.900"
            >
              Manajemen Klien
            </c-heading>
            <c-menu :close-on-select="true">
              <c-menu-button
                borderRadius="40px"
                backgroundColor="superLightGray.900"
                pl="20px"
                pr="30px"
                py="10px"
                h="40px"
                color="gray.900"
                fontSize="14px"
                lineHeight="21px"
                fontFamily="Roboto"
                fontWeight="normal"
                :display="['none', 'flex']"
              >
                <c-image
                  :src="require('@/assets/ic-filter.svg')"
                  alt="icon"
                  display="inline-block"
                  marginRaight="5px"
                />
                Filter
              </c-menu-button>
              <c-menu-list z-index="10" min-width="240px">
                <c-menu-option-group
                  @change="status = $event"
                  default-value="Semua"
                  type="radio"
                >
                  <c-menu-item-option
                    v-for="item in statuses"
                    :key="item.value"
                    :value="item.value"
                    border-bottom="1px solid #F2F2F2"
                    font-size="18px"
                    line-height="27px"
                    py="16px"
                    px="20px"
                  >
                    {{ item.label }}
                  </c-menu-item-option>
                </c-menu-option-group>
              </c-menu-list>
            </c-menu>
            <c-button
              v-if="false"
              borderRadius="20px"
              backgroundColor="superLightGray.900"
              px="15px"
              py="4px"
              color="gray.900"
              fontSize="16px"
              lineHeight="24px"
              fontFamily="Roboto"
              fontWeight="700"
              :display="['none', 'inline-block']"
            >
              <c-image
                :src="require('@/assets/ic-filter.svg')"
                alt="icon"
                display="inline-block"
                marginRaight="5px"
              />
              Filter
            </c-button>
          </c-flex>
          <c-box
            :w="['100%', 'auto']"
            :marginTop="['0', '30px']"
            :marginBottom="['0', '30px']"
          >
            <c-form-control pos="relative" w="100%">
              <c-image
                :src="require('@/assets/ic-search.svg')"
                alt="icon"
                pos="absolute"
                top="10px"
                left="15px"
                zIndex="2"
              />
              <c-input
                type="text"
                w="100%"
                id="searchId"
                placeholder="Pencarian"
                background="superLightGray.900"
                borderRadius="12px"
                paddingLeft="50px"
                pos="relative"
                zIndex="1"
                v-model="search"
                v-chakra="{
                  '&::placeholder': {
                    color: 'gray.900',
                  },
                }"
              />
            </c-form-control>
          </c-box>
          <c-button
            borderRadius="0"
            backgroundColor="transparent"
            px="15px"
            py="4px"
            color="gray.900"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="Roboto"
            fontWeight="700"
            :display="['inline-block', 'none']"
          >
            <c-image
              :src="require('@/assets/ic-filter.svg')"
              alt="icon"
              display="inline-block"
              marginRaight="5px"
            />
          </c-button>

          <c-box
            :display="['none', 'none']"
            pos="fixed"
            left="0"
            bottom="0"
            w="100%"
            h="100%"
            zIndex="88"
            backgroundColor="rgba(0,0,0,0.5)"
          >
            <c-box
              backgroundColor="#ffffff"
              pos="absolute"
              left="0"
              bottom="0"
              w="100%"
              borderRadius="10px 10px 0 0"
            >
              <c-box padding="20px 20px" pos="relative">
                <c-text fontSize="16px" fontWeight="700" color="black.900">
                  Spesialisasi
                </c-text>
                <c-button
                  backgroundColor="transparent"
                  pos="absolute"
                  top="8px"
                  right="0px"
                >
                  <c-icon name="close" size="10px" />
                </c-button>
              </c-box>
              <c-box
                h="300px"
                overflow="auto"
                v-chakra="{
                  ul: {
                    li: {
                      padding: '10px 20px',
                      borderBottom: '1px solid #F2F2F2',
                      cursor: 'pointer',
                      fontSize: '12px',
                      pos: 'relative',
                      '&.active': {
                        backgroundColor: 'rgba(0, 140, 129, 0.1)',
                        color: 'brand.900',
                      },
                    },
                  },
                }"
              >
                <c-list spacing="0">
                  <c-list-item class="active">
                    Semua Spesialisasi
                    <c-icon
                      name="check"
                      fontSize="12px"
                      pos="absolute"
                      top="13px"
                      right="20px"
                    />
                  </c-list-item>
                  <c-list-item> Spesialisasi 1 </c-list-item>
                  <c-list-item> Spesialisasi 2 </c-list-item>
                  <c-list-item> Spesialisasi 3 </c-list-item>
                  <c-list-item> Spesialisasi 4 </c-list-item>
                  <c-list-item> Spesialisasi 5 </c-list-item>
                  <c-list-item> Spesialisasi 6 </c-list-item>
                  <c-list-item> Spesialisasi 7 </c-list-item>
                  <c-list-item> Spesialisasi 8 </c-list-item>
                  <c-list-item> Spesialisasi 9 </c-list-item>
                </c-list>
              </c-box>
            </c-box>
          </c-box>
        </c-box>

        <c-box w="100%" marginBottom="20px" borderBottom="1px solid #F2F2F2">
          <c-heading
            fontFamily="Roboto"
            fontSize="18px"
            fontWeight="500"
            color="superDarkGray.900"
            marginBottom="8px"
            :px="['20px', '0']"
          >
            List Klien
          </c-heading>
        </c-box>
        <c-grid
          w="100%"
          :px="['20px', '0']"
          :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
          :gap="[0, 6]"
        >
          <card-client
            v-for="client in clients"
            :key="client.id"
            :client="client"
          />
        </c-grid>
        <c-flex justifyContent="center" w="100%" marginTop="40px">
          <c-flex justifyContent="center">
            <btn-pagination bg="transparent" :disabled="currentPage === 1">
              <c-icon name="chevron-left" size="24px" />
            </btn-pagination>
            <btn-pagination
              v-for="page in pages"
              :key="page"
              :bg="
                page === currentPage
                  ? ['rgba(0, 140, 129, 0.3)']
                  : ['transparent']
              "
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page } }"
            >
              {{ page }}
            </btn-pagination>
            <btn-pagination>
              <c-icon name="chevron-right" size="24px" />
            </btn-pagination>
          </c-flex>
        </c-flex>
      </c-box>
    </c-box>

    <NavBottom />
    <Footer />
  </c-box>
</template>

<script>
import equal from "fast-deep-equal";
import Header from "@/components/header/index.vue";
import NavBottom from "@/components/nav-bottom.vue";
import Footer from "@/components/Footer.vue";
import CardClient from "./card-client.vue";
import BtnPagination from "./btn-pagination.vue";

export default {
  name: "ManagementClientPage",
  components: {
    BtnPagination,
    Header,
    NavBottom,
    Footer,
    CardClient,
  },
  data() {
    return {
      totalData: 0,
      perPage: 10,
      clients: [],
      search: this.$route.query.query ?? "",
      // status: '',
      statuses: [
        { label: "Semua", value: undefined },
        { label: "Aktif", value: "active" },
        { label: "Tidak Aktif", value: "non_active" },
        { label: "Pending", value: "pending" },
      ],
    };
  },
  computed: {
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10);
    },
    pages() {
      let total = this.totalData ?? 1;
      let perPage = this.perPage ?? 10;
      let length = Math.ceil(total / perPage);
      return Array.from({ length }, (_, idx) => idx + 1);
    },
    status: {
      get() {
        return this.$route.query.status ?? undefined;
      },
      set(v) {
        this.$router.replace({
          query: { ...this.$route.query, status: v },
        });
      },
    },
  },
  methods: {
    async listClients({ page = 1, query = null } = {}) {
      let axios = this.$store.getters.axios;
      let params = new URLSearchParams();
      params.set("perpage", this.perPage);
      params.set("page", `${page}`);
      if (this.status != null) params.set("status", this.status);
      if (query != null) params.set("q", query);

      axios
        .get(`/v1/nutritionist/clients?${params.toString()}`)
        .then((it) => it.data)
        .then((it) => {
          this.clients = it.data;
          this.totalData = it.meta.total;
        });
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.listClients(route.query);
      },
    },
    search: {
      immediate: true,
      handler(query, prev) {
        if (equal(query, prev)) return;

        this.$router.replace({
          query: {
            page: 1,
            query: query === "" ? undefined : query,
          },
        });
      },
    },
  },
};
</script>

<style></style>
